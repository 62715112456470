.Social{
    position: absolute;
    margin-top: 10px;
    display: flex;
    left: 43.5%;
}

.Social i{
    padding-right: 30px;
    font-size: 25px;
    color: #9a9b9c;
}

.Social i:hover{
    color: black;
}

@media screen and (max-width: 600px){
    .Social{
        left: 26%;
    }
}

@media screen and (min-width: 600px){
    .Social{
        left: 39%;
    }
}

@media screen and (min-width: 768px){
    .Social{
        left: 38%;
    }
}

@media screen and (min-width: 1200px){
    .Social{
        left: 43%;
    }
}