.Navbar{
    height: 40px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
}

.Navbar nav{
    height: 100%;
}

.Name{
    height: 80%;
}

@media(max-width: 499px){
    .DesktopOnly{
        display: none;
    }
}