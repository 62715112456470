.Banner{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

/* .Banner video{
    position: relative;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    pointer-events: none;
} */

.Text{
    color: white;
    z-index: 1;
    display: inline;
    position: absolute;
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
}

.Text p{
    text-align: center;
}

.Button{
    z-index: 1;
    margin: 0 0.3em 0.3em 0;
    text-align: center;
    display: inline-block;
    padding: 0.35em 1.2em;
    border: 0.1em solid #ffffff;
    border-radius: 0.12em;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: 300;
    color: #ffffff;
    transition: all 0.2s;
    background-color: black;
}

.Button:hover {
    color: #000000;
    background-color: #ffffff;
}

@media all and (max-width:30em){
    .Button{
        display: block;
        margin: 0.4em auto;
    }
}
