.Card{
    flex: 0 1 calc(25% - 1em);
    /* border: black solid 1px; */
    text-align: center;
    margin: 2px;
    /* border-radius: 12px; */
}

.Card img{
    width: 100px;
    height: 100px;
    margin: 0 auto;
    text-align: center;
}

.Card p{
    text-align: center;
    margin: 0 auto;
}

@media screen and (max-width: 600px){
    .Card{
        flex: 0 1 calc(25% - 1em);
    }
    .Card img{
        width: 50px;
        height: 50px;
        margin: 0 auto;
        text-align: center;
    }
}


@media screen and (min-width: 600px){
    .Card{
        flex: 0 1 calc(25% - 1em);
    }
}

@media screen and (min-width: 768px){
    .Card{
        flex: 0 1 calc(25% - 1em);
    }
}

@media screen and (min-width: 1200px){
    .Card{
        flex: 0 1 calc(25%-1em);
    }
}