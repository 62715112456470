.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.NavigationItem a{
    color: green;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem a:hover, 
.NavigationItem a:active, 
.NavigationItem a.active{
    color: #6B9AC4;
}

@media (min-width: 500px){
    .NavigationItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
    
    .NavigationItem a{
        color: white;
        height: 100%;
        padding: 10px 10px;
        border-bottom: 4px solid transparent;
    }
    
    .NavigationItem a:hover, 
    .NavigationItem a:active, 
    .NavigationItem a.active{
        border-bottom: 4px solid #6B9AC4;
        color: white;
    }
}