.SideDrawer{
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 200;
    background-color: white;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: transform 0.3s ease-in-out;
}

@media (min-width: 500px){
    .SideDrawer{
        display: none;
    }
}

.Open{
    transform: translateX(0);
}

.Closed{
    transform: translateX(-100%);
}

.Name{
    height: 11%;
    margin-bottom: 5px;
}

